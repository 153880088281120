// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  login: `登录`,
  logout: `登出`,
  my_profile: `我的资料`,
  basic_information: `基本信息`,
  edit: `编辑`,
  user_id: `用户ID`,
  username: `用户名`,
  account_type: `账户类型`,
  status: `状态`,
  currency: `货币`,
  created_time: `创建时间`,
  last_login: `最后登录`,
  prefix: `前缀`,
  cert: `证书`,
  wallet_type: `钱包类型`,
  product_information: `产品信息`,
  platform: `平台`,
  supported_currency: `支持的货币`,
  my_pt_allow: `我的PT允许`,
  bet_limit: `投注限制`,
  fish: `捕鱼`,
  live: `真人`,
  slot: `老虎机`,
  table: `桌面`,
  virtual: `虚拟`,
  member_tree: `会员树`,
  real: `真实`,
  test: `测试`,
  active: `激活`,
  suspended: `暂停`,
  locked: `锁定`,
  submit: `提交`,
  export: `导出`,
  view: `查看`,
  login_failure: `登录失败`,
  password_lock: `密码锁定`,
  input_x: `输入 {{x}}`,
  sub_account: `子账户`,
  add_sub_account: `添加子账户`,
  win_loss_summary: `盈亏汇总`,
  date_time: `日期和时间`,
  time_zone: `时区`,
  type: `类型`,
  location: `位置`,
  last_month: `上个月`,
  this_month: `本月`,
  last_week: `上周`,
  this_week: `本周`,
  yesterday: `昨天`,
  today: `今天`,
  view_all: `查看全部`,
  display_columns: `显示列`,
  player_summary: `玩家汇总`,
  valid_turnover: `有效成交`,
  total_bet: `总投注`,
  bet_amount: `投注金额`,
  player: `玩家`,
  player_win_loss: `玩家盈亏`,
  adjustment: `调整`,
  total_pl: `总盈亏`,
  margin: `利润`,
  pt_win_loss: `PT盈亏`,
  agent: `代理`,
  company: `公司`,
  grand_total_x: `总计 ({{x}})`,
  start_date: `开始日期`,
  end_date: `结束日期`,
  clear: `清除`,
  bet_count: `投注次数`,
  total: `总计`,
  all: `全部`,
  adjustment_detail: `调整细节`,
  adjustment_type: `调整类型`,
  game_type: `游戏类型`,
  adjustment_types: `调整类型`,
  items: `项目`,
  account_statement: `账户对账单`,
  player_id: `玩家ID`,
  debit: `借记`,
  credit: `贷记`,
  before_balance: `调整前余额`,
  after_balance: `调整后余额`,
  request_id: `请求ID`,
  tip_report: `小费报告`,
  no_data: `没有数据`,
  total_tip: `小费总计`,
  products: `产品`,
  push_message: `推送消息`,
  add_product: `添加产品`,
  send_notification: `发送通知`,
  product_name: `产品名称`,
  search: `搜索`,
  is_active: `是否激活`,
  permission: `权限`,
  currency_list: `货币列表`,
  add_currency: `添加货币`,
  edit_currency: `编辑货币`,
  venue_list: `场馆列表`,
  add_venue: `添加场馆`,
  edit_venue: `编辑场馆`,
  tier_list: `等级列表`,
  add_tier: `添加等级`,
  edit_tier: `编辑等级`,
  player_performance_list: `玩家表现列表`,
  groups_list: `组列表`,
  group_qr: `组二维码`,
  edit_group: `编辑组`,
  group_details: `组详情`,
  sub_agent_list: `子代理列表`,
  add_sub_agent: `添加子代理`,
  member_list: `成员列表`,
  add_member: `添加成员`,
  edit_member: `编辑成员`,
  full_name: `全名`,
  phone_number: `电话号码`,
  change_password: `更改密码`,
  current_password: `当前密码`,
  new_password: `新密码`,
  retype_password: `重新输入密码`,
  reset: `重置`,
  allocate_chips: `分配筹码`,
  approved_allocated_chips: `批准的分配筹码`,
  flight_and_accomodation: `航班和住宿`,
  win_loss_chips: `盈亏筹码`,
  x_must_be_y_characters: `{{x}} 必须至少为 {{y}} 个字符`,
  password_doesnt_match: `密码不匹配`,
  agents: `代理`,
  add_agent: `添加代理`,
  agents_list: `代理列表`,
  groups: `组`,
  name: `名称`,
  venue: `场馆`,
  group_id: `组ID`,
  yes: `是`,
  no: `否`,
  create_group: `创建组`,
  group: `组`,
  add: `添加`,
  edit_flight_total_cost: `编辑航班总费用`,
  edit_accommodation_total_cost: `编辑住宿总费用`,
  id: `ID`,
  flight_cost: `航班费用`,
  accommodation_cost: `住宿费用`,
  allocated_chips: `分配的筹码`,
  created_at: `创建于`,
  created_by: `创建者`,
  updated_at: `更新时间`,
  updated_by: `更新者`,
  optional: `可选`,
  main_agent: `主代理`,
  commission: `佣金`,
  enter_x: `输入 {{x}}`,
  back: `返回`,
  skip: `跳过`,
  next: `下一步`,
  group_n_agent: `组 & 代理`,
  venue_detail: `场馆详情`,
  itenerary_cost: `行程费用`,
  chips_entry: `筹码录入`,
  x_is_required: `{{x}} 是必填项`,
  action: `操作`,
  flight: `航班`,
  accommodation: `住宿`,
  company_win_loss: `公司盈亏`,
  insurance: `保险`,
  total_flight_cost: `总航班费用`,
  total_accommodation_cost: `总住宿费用`,
  total_member_win_loss: `总会员盈亏`,
  total_agent_commission: `总代理佣金`,
  total_group_adjustment: `总组调整`,
  total_company_win_loss: `总公司盈亏`,
  total_member_insurance: `总会员保险`,
  adjustment_success: `调整成功!`,
  generic_error_message: `出现错误，请联系支持。`,
  debit_adjustment: `借记调整`,
  credit_adjustment: `贷记调整`,
  remarks: `备注`,
  amount: `金额`,
  win_chips: `赢筹码`,
  lose_chips: `输筹码`,
  buy_insurance: `购买保险`,
  is_ended: `是否结束？`,
  transactions_list: `交易列表`,
  users: `用户`,
  inactive: `非活跃`,
  add_user: `添加用户`,
  edit_user: `编辑用户`,
  at_least_8: `至少 8 个字符`,
  min_char_is_x: `最少字符数为 {{x}}`,
  invalid_input: `无效输入`,
  user_added: `用户已添加`,
  user_edited: `用户已编辑`,
  group_edited: `组已编辑`,
  group_added: `组已添加`,
  currency_code: `货币代码`,
  symbol: `符号`,
  currency_added: `货币已添加`,
  currency_editted: `货币已编辑`,
  cancel: `取消`,
  agent_id: `代理ID`,
  agent_name: `代理名称`,
  password: `密码`,
  venue_name: `场馆名称`,
  country: `国家`,
  target_point: `目标点数`,
  venue_id: `场馆ID`,
  show_qr: `显示二维码`,
  group_information: `组信息`,
  enlarge_qr: `放大二维码`,
  select_agent: `选择代理`,
  is_main: `是否主代理`,
  flight_and_accommodation_cost: `航班和住宿费用`,
  flight_total_cost: `航班总费用`,
  accommodation_total_cost: `住宿总费用`,
  member_information: `会员信息`,
  win_lose: `盈亏`,
  over_all_win_lose_amount: `总体盈亏金额`,
  insurance_amount: `保险金额`,
  flight_accommodation: `航班/住宿`,
  transactions: `交易`,
  member_username: `会员用户名`,
  member_name: `会员姓名`,
  title: `标题`,
  created_date: `创建日期`,
  total_commission_amount: `总佣金金额`,
  agent_commission: `代理佣金`,
  last_login_at: `最后登录时间`,
  role: `角色`,
  turnover_amount: `成交金额`,
  tier: `等级`,
  lifetime_points: `终身积分`,
  current_point: `当前积分`,
  member_profile: `会员资料`,
  stats_by_currency: `按货币统计`,
  overall_win_lose_amount: `整体盈亏金额`,
  overall_turnover_amount: `整体成交金额`,
  flight_info: `航班信息`,
  accommodation_info: `住宿信息`,
  win_lose_amount: `盈亏金额`,
  sort_by: `排序依据`,
  winlose_desc: `盈亏降序`,
  winlose_asc: `盈亏升序`,
  turnover_desc: `成交降序`,
  turnover_asc: `成交升序`,
  add_turnover: `添加成交`,
  turnover: `成交`,
  view_member: `查看会员`,
  dashboard: `仪表板`,
  enter_amount: `输入金额`,
  please_select_country: `请选择国家`,
  scan_qr_warning: `扫描二维码以加入该组`,
  admin: `行政`,
  report: `报告`,
  tiers: `等级`,
  player_performance: `球员表现`,
  home: `家`,
  profile: `轮廓`,
  updated_date: `更新日期`,
  please_select_x: `请选择{{x}}`,
  tier_name: `层级名称`,
  page: `页`,
  group_performance: `团队表现`,
  report_agent: `报告`,
  group_name: `组名`,
  agent_username: '代理用户名',
  group_performance_list: `团队表现名单`,
  edit_agent: `编辑代理`,

  currency_rate_to_myr: `货币汇率（兑换为 MYR)`,
  position_taking: `采取立场`,
  runner: `跑步者`,
  agent_pt: `代理PT`,
  rebate: `回扣`,
  chips: `薯片`,
  all_player_default_credit_limit: `所有玩家的默认信用额度`,
  casino_pt: `赌场 PT`,
  top1_pt: `Top1 PT`,
  casino_commission: `赌场委员会`,
  root_top1_commission: `佣金排名第一`,
  pt: 'PT',
  casino: `赌场`,
  add_runner: `添加跑步者`,
  total_chips: '总筹码',
  join_date_from: '加入日期从',
  join_date_to: '加入日期至',
  win: '赢',
  lose: '输',
  member_phonenumber: '会员电话号码',
  transaction_type: '交易类型',
  date_from: '日期从',
  date_to: '日期至',
  belongs_to_agent: '所属代理',
  action_by: '操作人',
  credit_limit: '信用额度',
  join_date: '加入日期',
  deposit: '存款',
  withdraw: '取款',
  rebate_amount: '回扣金额',
  final_win_lose: '最终输赢',
  assign_chips: '分配筹码',
  set_turnover_amount: '设定流水金额',
  return_chips: '返还筹码',
  set_rebate_perc: '设定回扣百分比',
  set_credit_limit: '设定信用额度',
  set_insurance_amount: '设定保险金额',
  agent_id_must_be_unique: `发现重复的代理`,
  runner_id_must_be_unique: `发现重复的跑步者`,
  subadmin: `子管理员`,
  under_agent: '代理下',
  expenses: `开支`,
  agent_qr: `代理二维码`,
  generate_qr: `生成二维码`,
  total_agents: '总代理人数',
  total_players: '总玩家人数',
  total_player_win_lose: '总玩家输赢',
  total_player_turnover: '总玩家营业额',
  total_player_rebate_amount: '总玩家返利金额',
  total_player_final_win_lose: '总玩家最终输赢',
  top_1_total_pay: '第1名总支付',
  agents_total_pay: '代理总支付',
  total_expenses: '总费用',
  total_groups: '总组数',
  top1_pay: '第1名支付',
  agent_pay: '代理支付',
  group_start_date_from: '组开始日期从',
  group_start_date_to: '组开始日期到',
  agent_performance: '代理表现',
  list_of_expenses: `费用清单`,
  add_expense: `添加费用`,
  select_group: `选择组`,
  member: `成员`,
  remark: `评论`,
  max_is_x: `最大值为 {{x}}`,
  min_is_x: `分钟为 {{x}}`,
  expense_is_for: `费用是为了`,
  for_group: `团体用`,
  for_agent: `对于代理`,
  for_member: `对于会员`,
  select_member: `选择会员`,
  no_member_found: `没有找到会员`,
  no_agent_found: `没有找到代理`,
  total_win_lose: '总胜负',
  total_turnover: '总营业额',
  total_rebate_amount: '总返利金额',
  player_final_win_lose: '玩家最终胜负',
  can_not_be_more_than_top1: `不能超过前 1 名 PT`,
  group_total_chips: `团体总筹码`,
  please_select_user_type: '请选择您的用户类型',
  select_type: '选择类型',
  login_as_x: '以 {{x}} 身份登录',
  member_phone_number: `会员电话号码`,
  create_date_from: `创建日期自`,
  create_date_to: `创建日期至`,
  qr_code: '二维码',
  finalize: `最终确定`,
  confirm_finalize: `您确定要最终确定该组吗？`,
  proceed: `继续`,
  success: `成功!`,
  can_allocate_chip: `可分配芯片`,
  can_return_chip: `可退回芯片`,
  credit_limit_balance: `信用额度余额`,
  overall_final_wl_amount: `最终W/L总额`,
  overall_rebate_amount: `总回扣金额`,
  group_agent_name: `团体/代理名称`,
  has_pending_chips: `有待处理筹码`,
  final_wl_amount: `最终W/L金额`,
  group_stats: '组统计',
  chips_allocated: '分配的筹码',
  player_win_lose: '玩家输赢',
  chips_returned: '归还的筹码',
  last_24_hours: '过去 24 小时',
  accomodation_info: '住宿信息',
  remove_member: '移除成员',
  confirm_remove_member: '您确定要移除成员吗？',
  confirm_password: '确认密码',
  password_not_match: '密码不匹配',
  add_past_member: `添加过去的会员`,
  confirm_add: `确认添加`,
  confirm_add_member: `您确定要添加该会员吗？`,
};

export default cn;
